<template>
  <v-row justify="center">
    <v-dialog
      origin="top center"
      v-model="barcodeDialog"
      persistent
      content-class="barcode-setting-dialog"
      :max-width="dialogWidth"
    >
      <v-card>
        <v-card-title
          class="headline dialog-custom-header white-background custom-border-bottom custom-header-blue-text"
        >
          {{ lodash.upperCase(endpoint) }} #
        </v-card-title>
        <v-card-text class="p-8 font-size-16">
          <p>
            Your {{ lodash.lowerCase(endpoint) }} numbers are set on
            auto-generate mode to save your time. Are you sure about changing
            this setting?
          </p>
          <p>
            Future {{ lodash.lowerCase(endpoint) }}s will be assigned the next
            highest next number
          </p>
          <v-row>
            <v-col cols="4">
              <label class="ml-1">Prefix</label>
              <v-text-field
                dense
                v-model.trim="fields.prefix_string"
                filled
                :disabled="formLoading"
                color="cyan"
                label="Prefix"
                solo
                flat
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <label class="ml-1">Next Number</label>
              <v-text-field
                dense
                v-model.trim="fields.next_number"
                filled
                :disabled="formLoading"
                color="cyan"
                label="Next Number"
                v-mask="'######'"
                solo
                flat
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <label class="ml-1">Number Length</label>
              <v-text-field
                dense
                v-model.trim="fields.number_length"
                filled
                :disabled="formLoading"
                color="cyan"
                v-mask="'##'"
                label="Number Length"
                solo
                flat
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider class="my-0"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mx-2 custom-bold-button white--text"
            color="cyan"
            :disabled="formLoading"
            :loading="formLoading"
            v-on:click="updateBarcodeSetting"
          >
            Save
          </v-btn>
          <v-btn
            class="mx-2 custom-grey-border custom-bold-button"
            depressed
            :disabled="formLoading"
            v-on:click="$emit('close:barcode-dialog', false)"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<style scoped>
.v-dialog__content {
  align-items: start;
}
</style>

<script>
import { ErrorEventBus } from "@/core/lib/message.lib";
import { PUT } from "@/core/services/store/request.module";

export default {
  props: {
    barcodeDialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    dialogWidth: {
      type: Number,
      default: 768,
    },
    barcodeSetting: {
      type: Object,
      required: true,
      default: () => {
        return new Object();
      },
    },
    endpoint: {
      type: String,
      required: true,
      default: "",
    },
  },
  watch: {
    barcodeSetting: {
      deep: true,
      immediate: true,
      handler(param) {
        this.fields = param;
      },
    },
  },
  data() {
    return {
      formLoading: false,
      fields: {
        prefix_string: null,
        next_number: null,
        number_length: null,
      },
    };
  },
  methods: {
    updateBarcodeSetting() {
      const _this = this;
      let errorCheck = false;
      if (!_this.fields.prefix_string) {
        ErrorEventBus.$emit("update:error", "Prefix is required.");
        errorCheck = true;
      }
      if (
        !_this.fields.next_number ||
        _this.lodash.toSafeInteger(_this.fields.next_number) <= 0
      ) {
        ErrorEventBus.$emit("update:error", "Next Number is required.");
        errorCheck = true;
      }
      if (
        !_this.fields.number_length ||
        _this.lodash.toSafeInteger(_this.fields.number_length) <= 0
      ) {
        ErrorEventBus.$emit("update:error", "Number Length is required.");
        errorCheck = true;
      }
      if (errorCheck) {
        return false;
      }

      _this.formLoading = true;
      _this.$store
        .dispatch(PUT, {
          url: "barcode-setting/" + _this.endpoint,
          data: _this.fields,
        })
        .then(({ data }) => {
          // _this.fields = data;

          console.log(data);
          _this.$emit("update:barcode-setting", data);
          _this.$emit("close:barcode-dialog");
          _this.$emit("success");
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
  },
  mounted() {
    this.fields = this.barcodeSetting;
  },
};
</script>
