<template>
  <CreateUpdateTemplate
    :routePreventDialog="routePreventDialog"
    :customClass="'project-create create-form'"
    v-if="getPermission('project:create')"
  >
    <template v-slot:header-title>
      <v-skeleton-loader
        v-if="pageLoading"
        class="custom-skeleton custom-skeleton-full-width"
        type="button"
      ></v-skeleton-loader>
      <!-- <h1 v-else class="form-title d-flex">
        Project For
       
       
          <template v-if="lodash.isEmpty(customerObject)">Client Name  {{ vCustomerId }}</template>
          <div v-if="!vCustomerId"
              class="ml-3 form-title-link cursor-pointer text-h5"
              v-on:click="openDialog()"
            >
              Select a Company
              <v-icon large color="cyan">mdi-account-check-outline</v-icon>
              <div class="mx-3">OR</div>
            <div
              class="form-title-link cursor-pointer cyan--text text--darken-4 text-h5"
              v-on:click="createCustomerDialog()"
            >
              Create a new Company
              <v-icon large color="cyan darken-4"
                >mdi-plus-circle-outline</v-icon
              >
            </div>
        </div>
        
          <template v-else>  <span class="form-title-create-link orange--text" link  v-on:click="openDialog()">{{
            customerObject.display_name
          }}</span></template>
         
      
      </h1>
       -->
      <h1 class="form-title text-truncate d-flex align-baseline">
        Project for
        <div
          v-if="vCustomerId"
          class="ml-3 form-title-link cursor-pointer text-h5 orange--text"
          v-on:click="openDialog('customer')"
        >
          {{ customerObject?.display_name }}
        </div>
        <template v-else>
          <div
            class="ml-3 form-title-link cursor-pointer text-h5"
            v-on:click="openDialog('customer')"
          >
            Select a Company
            <v-icon large color="cyan">mdi-account-check-outline</v-icon>
          </div>
          <!-- <div class="mx-3">OR</div>
            <div
              class="form-title-link cursor-pointer cyan--text text--darken-4 text-h5"
              v-on:click="createCustomerDialog()"
            >
              Create a new Customer
              <v-icon large color="cyan darken-4"
                >mdi-plus-circle-outline</v-icon
              >
            </div> -->
        </template>
      </h1>
    </template>

    <template v-slot:header-action>
      <v-btn
        v-on:click="goBack"
        :disabled="formLoading"
        class="mx-2 custom-grey-border custom-bold-button"
      >
        Cancel
      </v-btn>
      <v-btn
        :disabled="!formValid || formLoading || pageLoading"
        :loading="formLoading"
        class="mx-2 custom-bold-button white--text"
        v-on:click="onSubmit"
        color="cyan"
      >
        Save Project
      </v-btn>
    </template>
    <template v-slot:body>
      <v-form
        ref="projectForm"
        v-model.trim="formValid"
        lazy-validation
        v-on:submit.stop.prevent="onSubmit"
      >
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll custom-box-top-inner-shadow product-create-height"
          style="position: relative"
        >
          <div class="bg-white px-2">
            <v-row>
              <v-col cols="7">
                <v-container fluid>
                  <div>
                    <label class="font-weight-700 font-size-16 required"
                      >Project Name
                    </label>
                    <TextValidateInput
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      :rules="[
                        validateRules.required(
                          projectCreate.name,
                          'Project Name'
                        ),
                        validateRules.maxLength(
                          projectCreate.name,
                          'Project Name',
                          50
                        ),
                      ]"
                      id="display-name"
                      placeholder="Name"
                      counter="50"
                      :validationField="{
                        url_type: 'project',
                        filter_type: 'project',
                        field: 'name',
                      }"
                      :parent-id="projectCreate.id"
                      :current-id="projectCreate.id"
                      show-dropdown
                      v-model="projectCreate.name"
                      hideTopMargin
                    ></TextValidateInput>
                  </div>
                  <div>
                    <TextAreaField
                      auto-grow
                      dense
                      filled
                      color="cyan"
                      v-model.trim="projectCreate.description"
                      placeholder="Description"
                      solo
                      flat
                      row-height="25"
                      counter="250"
                    />
                  </div>

                  <div v-if="vCustomerId > 0">
                    <v-layout class="my-1 bg-quo">
                      <v-flex md6 class="ml-2">
                        <table width="100%">
                          <tr>
                            <td class="font-weight-600">
                              <label>Billing Address</label>
                              <!-- v-if="customer && customer.id" -->
                              <!-- v-on:click="customerBillingDialog = true" -->
                              <!-- <v-icon
                                v-on:click="openDialog('billing')"
                                small
                                class="ml-3"
                                color="cyan"
                                >mdi-pencil</v-icon
                              > -->
                            </td>
                          </tr>
                          <tr>
                            <td class="py-0">
                              <!-- <div
                                v-if="billing && billing.property_name"
                                style="
                                  font-weight: 600 !important;
                                  color: black;
                                "
                              >
                                {{ billing.property_name }}
                              </div> -->
                              <template v-if="vBilling?.id">
                                <span
                                  v-if="vBilling && vBilling.property_address"
                                >
                                  <!--  <v-icon small>mdi-map-marker</v-icon> --></span
                                >
                                <template>
                                  <div
                                    class="custom-flex-d"
                                    v-if="vBilling.property_name"
                                  >
                                    <b>
                                      <v-icon
                                        style="
                                          font-size: 18px;
                                          margin-top: -3px !important;
                                        "
                                        color="#000"
                                        class="cmt-2"
                                      >
                                        mdi-home-map-marker
                                      </v-icon>
                                    </b>
                                    <span
                                      >{{ vBilling.property_name }}<br
                                    /></span>
                                  </div>
                                  <div v-else>
                                    <em class="text-muted">
                                      <v-icon
                                        style="
                                          font-size: 18px;
                                          margin-top: -3px !important;
                                        "
                                        color="#000"
                                        class="cmt-2"
                                      >
                                        mdi-home-map-marker
                                      </v-icon>
                                      no Site Name</em
                                    >
                                  </div>
                                </template>

                                <template>
                                  <div class="custom-flex-d">
                                    <b>
                                      <v-icon
                                        style="
                                          font-size: 18px;
                                          margin-top: -3px !important;
                                        "
                                        color="#000"
                                        class="cmt-2"
                                      >
                                        mdi-map-marker
                                      </v-icon>
                                    </b>
                                    <span>
                                      <template v-if="vBilling.unit_no"
                                        >{{ vBilling.unit_no }},</template
                                      >
                                      <template v-if="vBilling.street_1"
                                        >{{ vBilling.street_1 }},</template
                                      >
                                      {{ vBilling.street_2 }}
                                      <br />
                                      {{ vBilling.country }}
                                      <template
                                        v-if="
                                          vBilling.zip_code &&
                                          vBilling.zip_code != '000000'
                                        "
                                      >
                                        {{ vBilling.zip_code }}
                                      </template>
                                    </span>
                                  </div>
                                </template>
                              </template>
                              <template v-else-if="vCustomerId">
                                <p class="mb-0 red--text">
                                  Please select billing location
                                </p>
                              </template>
                              <template v-else>
                                <p class="mb-0 red--text">
                                  Please select company
                                </p>
                              </template>
                            </td>
                          </tr>
                          <tr>
                            <td class="font-weight-600">
                              <label>Contact details</label>

                              <!--  <v-icon
                                class="ml-3"
                                v-on:click="openDialog('billing-contact')"
                                color="cyan"
                                small
                                >mdi-pencil</v-icon
                              > -->
                            </td>
                          </tr>

                          <template v-if="vBillingContact?.id">
                            <tr
                              v-if="
                                vBillingContact && vBillingContact?.display_name
                              "
                            >
                              <td class="py-0">
                                <label>{{
                                  vBillingContact?.display_name
                                }}</label>
                              </td>
                            </tr>
                            <tr>
                              <td class="py-0">
                                <label
                                  v-if="
                                    vBillingContact &&
                                    vBillingContact.primary_phone
                                  "
                                  >{{ vBillingContact.primary_phone }}</label
                                >
                                <em v-else class="text-muted">
                                  no phone number</em
                                >
                              </td>
                            </tr>
                            <tr>
                              <td class="py-0">
                                <label
                                  v-if="
                                    vBillingContact &&
                                    vBillingContact.primary_email
                                  "
                                  >{{ vBillingContact.primary_email }}</label
                                >
                                <em v-else class="text-muted"> no email</em>
                              </td>
                            </tr>
                          </template>
                          <template v-else-if="vCustomerId">
                            <p class="mb-0 red--text">
                              Please select contact person
                            </p>
                          </template>
                          <template v-else>
                            <tr>
                              <td class="font-weight-600 pt-0">
                                <p class="mb-0 red--text">
                                  Please select company
                                </p>
                              </td>
                            </tr>
                          </template>
                        </table>
                      </v-flex>
                      <v-flex md6 class="mr-2 custom-border-right">
                        <table width="100%">
                          <tr>
                            <td class="font-weight-600">
                              <label>Site Location</label>
                              <!-- <v-icon
                                class="ml-3"
                                v-on:click="openDialog('property')"
                                color="cyan"
                                small
                                >mdi-pencil</v-icon
                              > -->
                              <!--  <v-icon
                                class="ml-3"
                                v-on:click="routeToServiceHistory()"
                                color="cyan"
                                small
                                >mdi-history</v-icon
                              > -->
                            </td>
                          </tr>
                          <tr>
                            <td class="py-0">
                              <!-- <div
                              v-if="property && property.property_name"
                                style="
                                  font-weight: 600 !important;
                                  color: black;
                                "
                              >
                                {{ property.property_name }}
                              </div> -->

                              <template v-if="vProperty?.id">
                                <span
                                  v-if="vProperty && vProperty.property_address"
                                >
                                  <!--   <v-icon small>mdi-map-marker</v-icon>--></span
                                >
                                <p
                                  class="mr-2"
                                  v-if="vProperty && vProperty.property_address"
                                >
                                  <template>
                                    <div
                                      class="custom-flex-d"
                                      v-if="vProperty?.property_name"
                                    >
                                      <b>
                                        <v-icon
                                          style="
                                            font-size: 18px;
                                            margin-top: -3px !important;
                                          "
                                          color="#000"
                                          class="cmt-2"
                                        >
                                          mdi-home-map-marker
                                        </v-icon>
                                      </b>
                                      <span
                                        >{{ vProperty?.property_name }}<br
                                      /></span>
                                    </div>
                                    <div v-else>
                                      <em class="text-muted">
                                        <v-icon
                                          style="
                                            font-size: 18px;
                                            margin-top: -3px !important;
                                          "
                                          color="#000"
                                          class="cmt-2"
                                        >
                                          mdi-home-map-marker
                                        </v-icon>
                                        no Site Name</em
                                      >
                                    </div>
                                  </template>

                                  <template>
                                    <div class="custom-flex-d">
                                      <b>
                                        <v-icon
                                          style="
                                            font-size: 18px;
                                            margin-top: -3px;
                                          "
                                          color="#000"
                                          class="cmt-2"
                                        >
                                          mdi-map-marker
                                        </v-icon>
                                      </b>
                                      <span>
                                        <template v-if="vProperty?.unit_no"
                                          >{{ vProperty?.unit_no }},</template
                                        >
                                        <template v-if="vProperty?.street_1"
                                          >{{ vProperty?.street_1 }},</template
                                        >
                                        {{ vProperty?.street_2 }}
                                        <br />
                                        {{ vProperty?.country }}
                                        <template
                                          v-if="
                                            vProperty?.zip_code &&
                                            vProperty?.zip_code != '000000'
                                          "
                                        >
                                          {{ vProperty?.zip_code }}
                                        </template>
                                      </span>
                                    </div>
                                  </template>
                                </p>
                              </template>
                              <template v-else-if="vCustomerId">
                                <p class="mb-0 red--text">
                                  Please select site location
                                </p>
                              </template>
                              <template v-else>
                                <p class="mb-0 red--text">
                                  Please select company
                                </p>
                              </template>
                            </td>
                          </tr>
                          <tr>
                            <td class="font-weight-600">
                              <label>Contact details </label>
                              <v-chip
                                v-if="
                                  property_contact_person &&
                                  property_contact_person.tenant
                                "
                                small
                                color="green white--text"
                                label
                                class="ml-2 tenant-small-chip"
                                >Tenant</v-chip
                              >
                              <!--  <v-icon
                                class="ml-3"
                                v-on:click="openDialog('property-contact')"
                                color="cyan"
                                small
                                >mdi-pencil</v-icon
                              > -->
                            </td>
                          </tr>
                          <template v-if="vPropertyContact?.id">
                            <tr
                              v-if="
                                vPropertyContact &&
                                vPropertyContact?.display_name
                              "
                            >
                              <td class="py-0">
                                <label>{{
                                  vPropertyContact?.display_name
                                }}</label>
                              </td>
                            </tr>
                            <tr>
                              <td class="py-0">
                                <label v-if="vPropertyContact.primary_phone">{{
                                  vPropertyContact.primary_phone
                                }}</label>
                                <em v-else class="text-muted">
                                  no phone number</em
                                >
                              </td>
                            </tr>
                            <tr>
                              <td class="py-0">
                                <label
                                  v-if="
                                    vPropertyContact &&
                                    vPropertyContact.primary_email
                                  "
                                  >{{ vPropertyContact.primary_email }}</label
                                >
                                <em v-else class="text-muted"> no email</em>
                              </td>
                            </tr>
                          </template>
                          <template v-else-if="vCustomerId">
                            <p class="mb-0 red--text">
                              Please select contact person
                            </p>
                          </template>
                          <template v-else>
                            <tr>
                              <td class="font-weight-600 pt-0">
                                <p class="mb-0 red--text">
                                  Please select company
                                </p>
                              </td>
                            </tr>
                          </template>
                        </table>
                      </v-flex>
                    </v-layout>
                  </div>
                </v-container>
              </v-col>
              <v-col cols="5" class="pb-0">
                <v-container fluid>
                  <table class="width-100">
                    <tr>
                      <td colspan="3" class="font-weight-700 font-size-16 pb-0">
                        Project details
                      </td>
                    </tr>
                    <tr>
                      <td class="font-size-16 pb-0 pt-0" width="200">
                        Project number
                      </td>
                      <td
                        class="font-weight-700 font-size-16 pb-0 pt-0 text-capitalize"
                        width="150"
                        :colspan="isUpdateMode ? 2 : 1"
                      >
                        <v-skeleton-loader
                          v-if="pageLoading"
                          class="custom-skeleton"
                          type="text"
                        ></v-skeleton-loader>
                        <template v-else>{{ projectCreate.barcode }}</template>
                      </td>
                      <td v-if="!isUpdateMode" class="font-size-16 pb-0 pt-0">
                        <v-btn
                          text
                          small
                          :disabled="pageLoading"
                          :loading="pageLoading"
                          class="mx-2 custom-bold-button"
                          v-on:click="barcodeDialog = true"
                          color="cyan"
                        >
                          Change
                        </v-btn>
                      </td>
                    </tr>
                    <tr>
                      <td width="200">
                        <label class="font-size-16 pb-0">Start Date </label>
                      </td>
                      <td
                        colspan="2"
                        class="pb-0"
                        content="Start Date *"
                        v-tippy="{ placement: 'top-start' }"
                      >
                        <DatePicker :pageLoading="pageLoading" solo
                        :placeholder="'Start Date *'" :default-date="
                        projectCreate.started_at "
                        v-model="projectCreate.started_at" mandatory: true />
                      </td>
                    </tr>
                    <tr>
                      <td width="200">
                        <label class="font-size-16 pb-0">End Date</label>
                      </td>
                      <td
                        colspan="2"
                        class="pb-0"
                        content="End Date"
                        v-tippy="{ placement: 'top-start' }"
                      >
                        <DatePicker :pageLoading="pageLoading" solo
                        :placeholder="'End Date'" :default-date="
                        projectCreate.deadline "
                        :min-date="projectCreate.started_at"
                        v-model="projectCreate.deadline" mandatory: true />
                      </td>
                    </tr>
                    <tr>
                      <td class="font-size-16 pb-0" width="200">
                        Customer Purchase Order
                      </td>
                      <td
                        colspan="2"
                        class="pb-0"
                        content="Customer Purchase Order"
                        v-tippy="{ placement: 'top-start' }"
                      >
                        <TextField
                          id="sap_cust"
                          dense
                          filled
                          :disabled="pageLoading"
                          :loading="pageLoading"
                          label="Customer Purchase Order"
                          solo
                          flat
                          counter="50"
                          v-model="projectCreate.customer_purchase_order"
                          color="cyan"
                          :maxlength="50"
                          hide-details
                        />
                      </td>
                    </tr>

                    <tr>
                      <td class="font-size-16 pb-0" width="200">Reference #</td>
                      <td
                        colspan="2"
                        class="pb-0"
                        content="Reference #"
                        v-tippy="{ placement: 'top-start' }"
                      >
                        <TextField
                          id="sap_cust"
                          dense
                          filled
                          :disabled="pageLoading"
                          :loading="pageLoading"
                          label="Reference #"
                          solo
                          flat
                          counter="50"
                          v-model="projectCreate.reference"
                          color="cyan"
                          :maxlength="50"
                          hide-details
                        />
                      </td>
                    </tr>

                    <tr>
                      <td class="font-size-16 pb-2" width="200">
                        Project Value
                      </td>
                      <td
                        colspan="2"
                        class="pb-0"
                        content="Project Value"
                        valign="top"
                        v-tippy="{ placement: 'top-start' }"
                      >
                        <v-text-field
                          dense
                          color="cyan"
                          filled
                          :disabled="pageLoading"
                          :loading="pageLoading"
                          type="number"
                          v-on:keypress="
                            limitDecimal($event, projectCreate.project_value)
                          "
                          v-on:keyup="projectValue"
                          v-model="projectCreate.project_value"
                          v-model.trim.number.lazy="projectCreate.project_value"
                          label="Project Value"
                          :rules="[
                            validateRules.minLength(
                              projectCreate.project_value,
                              'Project Value',
                              1
                            ),
                            validateRules.maxLength(
                              projectCreate.project_value,
                              'Project Value',
                              100
                            ),
                          ]"
                          solo
                          flat
                          hide-details
                        ></v-text-field>
                      </td>
                    </tr>

                    <tr>
                      <td class="font-size-16 pb-0" width="200">
                        Project Budget
                      </td>
                      <td
                        colspan="2"
                        v-on:keyup="projectValue"
                        class="pb-0"
                        content="Project Budget"
                        valign="top"
                        v-tippy="{ placement: 'top-start' }"
                      >
                        <v-text-field
                          dense
                          color="cyan"
                          filled
                          :disabled="pageLoading"
                          :loading="pageLoading"
                          type="number"
                          v-on:keypress="
                            limitDecimal($event, projectCreate.project_budget)
                          "
                          v-model="projectCreate.project_budget"
                          v-model.trim.number.lazy="
                            projectCreate.project_budget
                          "
                          label="Project Budget"
                          :rules="[
                            validateRules.minLength(
                              projectCreate.project_budget,
                              'Project Budget',
                              1
                            ),
                            validateRules.maxLength(
                              projectCreate.project_budget,
                              'Project Budget',
                              100
                            ),
                          ]"
                          solo
                          flat
                          hide-details
                        ></v-text-field>
                      </td>
                    </tr>
                    <tr>
                      <td class="font-size-16 pb-0" width="200">Managers</td>
                      <td
                        colspan="2"
                        class="pb-0"
                        content="Managers"
                        valign="top"
                        v-tippy="{ placement: 'top-start' }"
                      >
                        <v-autocomplete
                          dense
                          color="cyan"
                          filled
                          :items="managersList"
                          :disabled="pageLoading"
                          :loading="pageLoading"
                          v-model.trim="projectCreate.managers"
                          label="Managers"
                          solo
                          flat
                          item-color="cyan"
                          item-text="full_name"
                          item-value="id"
                          hide-details
                          multiple
                        >
                          <template v-slot:selection="{ item, index }">
                            <v-chip v-if="index === 0">
                              <span>{{ item.full_name }}</span>
                            </v-chip>
                            <span
                              v-if="index === 1"
                              class="grey--text font-size-12"
                            >
                              (+{{ projectCreate.managers.length - 1 }} others)
                            </span>
                          </template>
                          <template v-slot:no-data>
                            <v-list-item>
                              <v-list-item-content>
                                <v-list-item-title
                                  v-html="'No Member Found.'"
                                ></v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </v-autocomplete>
                      </td>
                    </tr>
                    <tr>
                      <td class="font-size-16 pb-0" width="200">
                        Team Members
                      </td>
                      <td
                        colspan="2"
                        class="pb-0"
                        content="Team Members"
                        valign="top"
                        v-tippy="{ placement: 'top-start' }"
                      >
                        <v-autocomplete
                          dense
                          color="cyan"
                          filled
                          :items="memberList"
                          :disabled="pageLoading"
                          :loading="pageLoading"
                          v-model.trim="projectCreate.members"
                          label="Members"
                          solo
                          flat
                          item-color="cyan"
                          item-text="full_name"
                          item-value="id"
                          hide-details
                          multiple
                        >
                          <template v-slot:selection="{ item, index }">
                            <v-chip v-if="index === 0">
                              <span>{{ item.full_name }}</span>
                            </v-chip>
                            <span
                              v-if="index === 1"
                              class="grey--text font-size-12"
                            >
                              (+{{ projectCreate.members.length - 1 }} others)
                            </span>
                          </template>
                          <template v-slot:no-data>
                            <v-list-item>
                              <v-list-item-content>
                                <v-list-item-title
                                  v-html="'No Member Found.'"
                                ></v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </v-autocomplete>
                      </td>
                    </tr>
                    <!-- 
                    <tr>
                      <td class="font-size-16 pb-0" width="200">
                        Specification
                      </td>
                      <td
                        colspan="2"
                        class="pb-0"
                        content="Specification"
                        v-tippy="{ placement: 'top-start' }"
                      >
                        <v-text-field
                          dense
                          color="cyan"
                          filled
                          :disabled="pageLoading"
                          :loading="pageLoading"
                          v-model.trim="projectCreate.specification"
                          label="Specification"
                          :rules="[
                            validateRules.minLength(
                              projectCreate.specification,
                              'Specification',
                              1
                            ),
                            validateRules.maxLength(
                              projectCreate.specification,
                              'Specification',
                              100
                            ),
                          ]"
                          solo
                          flat
                          hide-details
                        ></v-text-field>
                      </td>
                    </tr> -->
                  </table>
                </v-container>
              </v-col>
              <v-col cols="12">
                <v-card
                  flat
                  class="custom-grey-border remove-border-radius"
                  :disabled="pageLoading"
                >
                  <v-card-text class="p-6 font-size-16">
                    <v-row>
                      <v-col cols="6" class="pt-0 left-side-border" style="">
                        <h3
                          class="font-weight-700 custom-headline color-custom-blue"
                        >
                          Banker Guarantee
                        </h3>
                        <table width="100%">
                          <tr>
                            <td class="font-size-16 pb-0" width="200">
                              Bank Name
                            </td>
                            <td
                              class="pb-0"
                              content="Bank Name"
                              v-tippy="{ placement: 'top-start' }"
                            >
                              <v-autocomplete
                                dense
                                filled
                                color="cyan"
                                item-color="cyan"
                                :items="bankList"
                                label="Bank Name"
                                solo
                                flat
                                append-outer-icon="mdi-cog"
                                v-on:click:append-outer="
                                  manageBankNameDialog = true
                                "
                                item-text="text"
                                item-value="text"
                                class="pr-2 width-100"
                                v-model.trim="projectCreate.bank_name"
                              >
                                <template v-slot:no-data>
                                  <v-list-item>
                                    <v-list-item-content>
                                      <v-list-item-title
                                        v-html="'No Bank Found.'"
                                      ></v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>
                                </template>
                              </v-autocomplete>
                            </td>
                          </tr>
                          <tr>
                            <td class="font-size-16 pb-0" width="200">
                              Amount
                            </td>
                            <td
                              colspan="2"
                              class="pb-0"
                              content="Amount"
                              valign="top"
                              v-tippy="{ placement: 'top-start' }"
                            >
                              <v-text-field
                                dense
                                color="cyan"
                                filled
                                :disabled="pageLoading"
                                :loading="pageLoading"
                                type="number"
                                v-on:keypress="
                                  limitDecimal(
                                    $event,
                                    projectCreate.bank_amount
                                  )
                                "
                                v-model="projectCreate.bank_amount"
                                v-model.trim.number.lazy="
                                  projectCreate.bank_amount
                                "
                                label="Amount"
                                :rules="[
                                  validateRules.minLength(
                                    projectCreate.bank_amount,
                                    'Amount',
                                    1
                                  ),
                                  validateRules.maxLength(
                                    projectCreate.bank_amount,
                                    'Amount',
                                    100
                                  ),
                                ]"
                                solo
                                flat
                                hide-details
                              ></v-text-field>
                            </td>
                          </tr>

                          <tr>
                            <td
                              class="font-size-16 pb-0"
                              width="200"
                              valign="top"
                            >
                              Remark
                            </td>
                            <td
                              colspan="2"
                              class="pb-0"
                              content="Remark"
                              valign="top"
                              v-tippy="{ placement: 'top-start' }"
                            >
                              <TextAreaField
                                auto-grow
                                dense
                                filled
                                color="cyan"
                                v-model="projectCreate.bank_remark"
                                placeholder="Remark"
                                solo
                                flat
                                row-height="25"
                                counter="250"
                              />
                            </td>
                          </tr>

                          <tr>
                            <!--   <td class="font-size-16 pb-0" width="200" valign="top">
                                Documents/Attachment
                              </td> -->
                            <td
                              colspan="3"
                              class="pb-0"
                              content="Documents/Attachment"
                              valign="top"
                              v-tippy="{ placement: 'top-start' }"
                            >
                              <div class="text-center">
                                <FileTemplate
                                  :attachments="bank_attachments"
                                  v-on:file:updated="
                                    updateFiles('bank', $event)
                                  "
                                  allowUpload
                                ></FileTemplate>
                              </div>
                            </td>
                          </tr>
                        </table>
                      </v-col>
                      <v-col cols="6" class="pt-0">
                        <h3
                          class="font-weight-700 custom-headline color-custom-blue"
                        >
                          Insurance Project
                        </h3>
                        <!-- <tr>
                          <td colspan="3" class="font-weight-700 font-size-16 pb-0">
                            Insurance Project
                          </td>
                        </tr> -->
                        <table width="100%">
                          <tr>
                            <td class="font-size-16 pb-0" width="200">
                              Insurance Company
                            </td>
                            <td
                              colspan="2"
                              class="pb-0"
                              content="Insurance Company"
                              v-tippy="{ placement: 'top-start' }"
                            >
                              <v-autocomplete
                                dense
                                color="cyan"
                                filled
                                :items="insuranceList"
                                :disabled="pageLoading"
                                :loading="pageLoading"
                                v-model.trim="projectCreate.insurance_company"
                                label="Insurance Company"
                                solo
                                flat
                                item-color="cyan"
                                item-text="text"
                                item-value="text"
                                hide-details
                                append-outer-icon="mdi-cog"
                                v-on:click:append-outer="
                                  manageInsuranaceCompanyDialog = true
                                "
                              >
                                <template v-slot:no-data>
                                  <v-list-item>
                                    <v-list-item-content>
                                      <v-list-item-title
                                        v-html="'No Insurance Company Found.'"
                                      ></v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>
                                </template>
                              </v-autocomplete>
                            </td>
                          </tr>

                          <tr>
                            <td class="font-size-16 pb-0" width="200">
                              Amount
                            </td>
                            <td
                              colspan="2"
                              class="pb-0"
                              content="Amount"
                              valign="top"
                              v-tippy="{ placement: 'top-start' }"
                            >
                              <v-text-field
                                dense
                                color="cyan"
                                filled
                                :disabled="pageLoading"
                                :loading="pageLoading"
                                type="number"
                                v-on:keypress="
                                  limitDecimal(
                                    $event,
                                    projectCreate.insurance_amount
                                  )
                                "
                                v-model="projectCreate.insurance_amount"
                                v-model.trim.number.lazy="
                                  projectCreate.insurance_amount
                                "
                                label="Amount"
                                :rules="[
                                  validateRules.minLength(
                                    projectCreate.insurance_amount,
                                    'Amount',
                                    1
                                  ),
                                  validateRules.maxLength(
                                    projectCreate.insurance_amount,
                                    'Amount',
                                    100
                                  ),
                                ]"
                                solo
                                flat
                                hide-details
                              ></v-text-field>
                            </td>
                          </tr>

                          <tr>
                            <td
                              class="font-size-16 pb-0"
                              width="200"
                              valign="top"
                            >
                              Remark
                            </td>
                            <td
                              colspan="2"
                              class="pb-0"
                              content="Remark"
                              valign="top"
                              v-tippy="{ placement: 'top-start' }"
                            >
                              <TextAreaField
                                auto-grow
                                dense
                                filled
                                color="cyan"
                                v-model="projectCreate.insurance_remark"
                                placeholder="Remark"
                                solo
                                flat
                                row-height="25"
                                counter="250"
                              />
                            </td>
                          </tr>

                          <tr>
                            <!--   <td class="font-size-16 pb-0" width="200" valign="top">
                            Documents/Attachment
                          </td> -->
                            <td
                              colspan="3"
                              class="pb-0"
                              content="Documents/Attachment"
                              valign="top"
                              v-tippy="{ placement: 'top-start' }"
                            >
                              <div class="text-center">
                                <FileTemplate
                                  :attachments="insurance_attachments"
                                  v-on:file:updated="
                                    updateFiles('insurance', $event)
                                  "
                                  allowUpload
                                ></FileTemplate>
                              </div>
                            </td>
                          </tr>
                        </table>
                        <!-- <editor v-model="notesAttachment.admin_notes" /> -->
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12">
                <div class="mt-4">
                  <h3 class="font-weight-700 custom-headline color-custom-blue">
                    Attachment
                  </h3>
                  <div class="text-center">
                    <FileTemplate
                      :attachments="project_attachments"
                      v-on:file:updated="updateFiles('project', $event)"
                      allowUpload
                    ></FileTemplate>
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
        </perfect-scrollbar>
      </v-form>
      <BarcodeSetting
        endpoint="projects"
        :barcodeDialog="barcodeDialog"
        :dialogWidth="600"
        :barcodeSetting="barcodeSetting"
        v-on:close:barcode-dialog="barcodeDialog = false"
        @update:barcode-setting="handleBarcodeSetting"
      ></BarcodeSetting>
      <ManageBankName
        project
        :c-dialog="manageBankNameDialog"
        :bank="bankList"
        v-on:close-dialog="manageBankNameDialog = false"
        v-on:get-bank-name="getOptions"
      ></ManageBankName>

      <ManageInsuranceCompany
        project
        :c-dialog="manageInsuranaceCompanyDialog"
        :insurance="insuranceList"
        v-on:close-dialog="manageInsuranaceCompanyDialog = false"
        v-on:get-insurance-company="getOptions"
      ></ManageInsuranceCompany>

      <address-select
        :dialog="propertyDialog"
        v-on:close="propertyDialog = false"
        :type="propertyType"
        :label="propertyLabel"
      />
      <person-select
        :dialog="contactDialog"
        v-on:close="contactDialog = false"
        :type="contactType"
      />
      <customer-select
        :dialog="reCustomerDialog"
        @close="closeCustomerDialog"
        @success="getOptions()"
      />
    </template>
  </CreateUpdateTemplate>
</template>

<script>
import { ErrorEventBus } from "@/core/lib/message.lib";

import CommonMixin from "@/core/plugins/common-mixin";
import CreateUpdateTemplate from "@/view/pages/partials/Create-Update-Template.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ManageBankName from "@/view/pages/partials/Manage-Bank-Name.vue";
import ManageInsuranceCompany from "@/view/pages/partials/Manage-Insurance-Company.vue";
// import ObjectPath from "object-path";
import {
  SET_BILLING_CONTACT,
  SET_PROPERTY_CONTACT,
  RESET_STATE,
  RESET_CREATE_STATE,
  SET_CUSTOMER,
  SET_PROPERTY,
  SET_BILLING,
} from "@/core/services/store/visit.module";
import {
  SET_DIALOG_STATUS,
  CLEAR_CUSTOMER,
} from "@/core/services/store/customer.module";

import {
  PUT,
  GET,
  POST,
  QUERY,
  CLEAR_ERROR,
} from "@/core/services/store/request.module";
import ValidationMixin from "@/core/plugins/validation-mixin";
import DatePicker from "@/view/pages/partials/Datepicker.vue";
import BarcodeSetting from "@/view/pages/partials/Barcode-Setting.vue";
import PersonSelect from "@/view/components/PersonSelect.vue";
import AddressSelect from "@/view/components/AddressSelect.vue";
import TextValidateInput from "@/view/pages/partials/TextValidateInput.vue";
import { PreventDialogEventBus } from "@/core/lib/prevent.reload.lib";
import FileTemplate from "@/view/pages/partials/FileTemplate.vue";
import CustomerSelect from "@/view/components/CustomerSelect.vue";
import TextAreaField from "@/view/components/app-component/TextAreaField.vue";

import moment from "moment-timezone";
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);
import { mapGetters } from "vuex";

export default {
  mixins: [CommonMixin, ValidationMixin],
  name: "project-create",
  title: "Create Project",
  data() {
    return {
      projectCreated: false,
      propertyType: null,
      propertyLabel: null,
      contactType: null,
      manageBankNameDialog: false,
      manageInsuranaceCompanyDialog: false,
      // customerPropertyDialog: false,
      propertyDialog: false,
      contactDialog: false,
      duplicate: 0,
      pageLoading: true,
      isUpdateMode: false,
      bankList: [],
      insuranceList: [],

      barcodeDialog: false,
      isDuplicateMode: false,
      changeAttention: false,
      billing_contact_person: new Object(),
      customerObject: new Object(),
      project: 0,
      project_attachments: [],
      property: new Object(),
      property_contact_person: new Object(),
      bank_attachments: [],
      insurance_attachments: [],
      quotation_attachments: [],
      projectCreate: new Object({
        name: null,
        description: null,
        project_attachments: [],
        barcode: null,
        started_at: moment().format("YYYY-MM-DD"),
        deadline: moment().add(1, "month").format("YYYY-MM-DD"),
        status: 0,
        customer_purchase_order: null,
        reference: null,
        serial_no: null,
        project_value: null,
        project_budget: null,
        members: [],
        managers: [],
        specification: null,
        bank_name: null,
        bank_amount: null,
        bank_remark: null,
        bank_attachments: [],
        quotation_attachments: [],
        insurance_company: null,
        insurance_amount: null,
        insurance_remark: null,
        insurance_attachments: [],
        customer: null,
        property: null,
        billing: null,
        property_contact_person: null,
        billing_contact_person: null,
      }),
      contactPersonObject: new Object(),
      barcodeSetting: new Object(),
      customer: null,
      projectProperty: null,
      memberList: [],
      managersList: [],
      billing: null,
      billingTypeList: [
        { text: "Fixed Rate", value: 1 },
        { text: "Project Hours", value: 2 },
      ],
    };
  },
  components: {
    CreateUpdateTemplate,
    DatePicker,
    BarcodeSetting,
    CustomerSelect,
    AddressSelect,
    FileTemplate,
    ManageBankName,
    ManageInsuranceCompany,
    PersonSelect,
    TextValidateInput,
    TextAreaField,
  },
  watch: {
    formValid(param) {
      if (param) {
        this.$store.dispatch(CLEAR_ERROR, {});
      }
    },
    duplicate(param) {
      if (param > 0) {
        this.getProject(param);
      }
    },
    project(param) {
      if (param > 0) {
        this.getProject(param);
        this.$store.dispatch(SET_BREADCRUMB, [
          { title: "Project", route: "project" },
          { title: "update" },
        ]);
      }
    },
  },
  methods: {
    handleBarcodeSetting(data) {
      this.projectCreate.barcode = data;
      this.projectCreate.serial_no = data;
    },

    openDialog(type) {
      this.$store.commit(SET_DIALOG_STATUS, false);
      this.propertyType = null;
      this.propertyLabel = null;
      this.propertyDialog = false;
      this.contactType = null;
      this.contactDialog = false;

      if (type == "customer") {
        this.$store.commit(SET_DIALOG_STATUS, true);
      } else {
        if (!this.vCustomerId) {
          ErrorEventBus.$emit("update:error", "Please select company.");
          return false;
        }
        if (type == "billing") {
          this.propertyType = "billing";
          this.propertyLabel = "Billing Location";
          this.propertyDialog = true;
        }
        if (type == "billing-contact") {
          this.contactType = "billing";
          this.contactDialog = true;
        }
        if (type == "property") {
          this.propertyType = "property";
          this.propertyLabel = "Site Location";
          this.propertyDialog = true;
        }
        if (type == "property-contact") {
          this.contactType = "property";
          this.contactDialog = true;
        }
      }
    },
    closeCustomerDialog() {
      this.$store.commit(SET_DIALOG_STATUS, false);
    },

    selectCustomerProperty(param) {
      this.customerProperty = param;
      this.closeDialog();
      this.checkProject();
    },
    selectCustomerPerson(param) {
      this.customerProperty = param;
      this.closeDialog();
      this.checkProject();
    },
    resetAll() {
      this.customer = 0;
      this.customerPerson = 0;
      this.customerProperty = 0;
      this.closeDialog();
    },

    closeDialog() {
      this.customerPersonDialog = false;
      // this.customerPropertyDialog = false;
    },
    projectValue() {
      if (
        this.projectCreate.project_budget >
        (this.projectCreate.project_value && this.projectCreate.project_value)
      ) {
        this.projectCreate.project_budget = this.projectCreate.project_value;
        return false;
      }
    },

    updateFiles(type, param) {
      if (type == "project") {
        this.projectCreate.project_attachments = param;
      }

      if (type == "bank") {
        this.projectCreate.bank_attachments = param;
      }

      if (type == "insurance") {
        this.projectCreate.insurance_attachments = param;
      }

      if (type == "quotation") {
        this.projectCreate.quotation_attachments = param;
      }
    },

    getProject(projectId) {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "projects/" + projectId,
        })
        .then(({ data }) => {
          _this.projectCreate.project = data.project;
          _this.projectCreate.barcode = data.barcode;
          _this.projectCreate.name = data.name;
          _this.projectCreate.description = data.description;
          _this.projectCreate.billing_person = data.billing_person;
          this.$store.commit(
            SET_BILLING_CONTACT,
            _this.projectCreate.billing_person
          );
          _this.projectCreate.property_person = data.property_person;
          this.$store.commit(
            SET_PROPERTY_CONTACT,
            _this.projectCreate.property_person
          );
          this.$store.commit(SET_PROPERTY, data.property);

          this.$store.commit(SET_BILLING, data.billing);

          _this.projectCreate.customer =
            data && data.customer && data.customer.id;
          this.$store.commit(
            SET_CUSTOMER,
            data && data.customer && data.customer
          );
          _this.projectCreate.property =
            data && data.property && data.property.id;
          _this.projectCreate.billing = data && data.billing && data.billing.id;
          _this.projectCreate.customer_purchase_order =
            data.customer_purchase_order;
          _this.projectCreate.reference = data.reference;
          _this.projectCreate.project_value = data.project_value;
          _this.projectCreate.project_budget = data.project_budget;

          _this.projectCreate.specification = data.specification;
          _this.projectCreate.bank_name = data.bank_name;
          _this.projectCreate.bank_amount = data.bank_amount;
          _this.projectCreate.bank_remark = data.bank_remark;

          _this.projectCreate.insurance_company = data.insurance_company;
          _this.projectCreate.insurance_amount = data.insurance_amount;
          _this.projectCreate.insurance_remark = data.insurance_remark;

          _this.projectCreate.attention = data.attention;
          _this.projectCreate.reference = data.reference;
          _this.projectCreate.billing_type = data.billing_type;
          _this.projectCreate.rate_per_hour = data.rate_per_hour || null;
          _this.projectCreate.estimated_hour = data.estimated_hour || null;
          _this.projectCreate.started_at = data.started_at;
          _this.projectCreate.deadline = data.deadline;
          _this.projectCreate.status = data.status;

          if (data.members && data.members.length > 0) {
            _this.projectCreate.members = data.members.map((row) => {
              return row.user.id;
            });
          }
          if (data?.managers && data.managers?.length > 0) {
            _this.projectCreate.managers = data.managers.map((row) => {
              return row.user.id;
            });
          }
          this.getOptions();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    setStartDate(param) {
      this.projectCreate.started_at = param;
    },
    setDeadline(param) {
      this.projectCreate.deadline = param;
    },
    getOptions() {
      const _this = this;
      _this.$store
        .dispatch(QUERY, {
          url: "company/options",
          data: {
            customer: _this.vCustomerId,
            property: _this.projectProperty,
          },
        })
        .then(({ data }) => {
          if (!_this.project) {
            _this.projectCreate.barcode = data.barcode;
          }
          _this.barcodeSetting = data.option;
          _this.contactPersonObject = data.contact_person;
          _this.projectCreate.attention = data?.contact_person?.display_name;
          _this.customerObject = data.customer;
          _this.projectCreate.customer = _this.vCustomerId;

          _this.memberList = data.members;
          _this.managersList = data?.managers;
          _this.insuranceList = data.insurance;
          _this.bankList = data.banks;

          if (!_this.lodash.isEmpty(data.billing)) {
            _this.billing = data.billing;
            this.$store.commit(SET_BILLING, _this.billing);

            _this.projectBilling = _this.billing.id;
            _this.projectCreate.billing = _this.billing.id;
          }

          if (!_this.lodash.isEmpty(data.property)) {
            _this.property = data.property;
            this.$store.commit(SET_PROPERTY, _this.property);

            _this.projectProperty = _this.property.id;
            _this.projectCreate.property = _this.property.id;
          }

          if (!_this.lodash.isEmpty(data.property_person)) {
            _this.property_contact_person = data.property_person;
            this.$store.commit(
              SET_PROPERTY_CONTACT,
              _this.property_contact_person
            );
            _this.projectPropertyPerson = _this.property_contact_person.id;
            _this.projectCreate.property_contact_person =
              _this.property_contact_person.id;
          }

          if (!_this.lodash.isEmpty(data.billing_person)) {
            _this.billing_contact_person = data.billing_person;

            this.$store.commit(
              SET_BILLING_CONTACT,
              _this.billing_contact_person
            );
            _this.projectBillingPerson = _this.billing_contact_person.id;
            _this.projectCreate.billing_contact_person =
              _this.property_contact_person.id;
          }
        })
        .catch((error) => {
          _this.logError(error);
          _this.goBack();
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    onSubmit() {
      const _this = this;
      try {
        if (!_this.$refs.projectForm.validate()) {
          return false;
        }
        _this.projectCreate.property_contact_person = this.vPropertyContactId;
        _this.projectCreate.billing_contact_person = this.vBillingContactId;

        _this.$store.dispatch(CLEAR_ERROR, {});

        _this.formLoading = true;

        let REQUEST_TYPE = POST;
        let REQUEST_URL = "projects";

        if (_this.project && _this.project > 0) {
          REQUEST_TYPE = PUT;
          REQUEST_URL = "projects/" + _this.project;
        }

        _this.$store
          .dispatch(REQUEST_TYPE, {
            url: REQUEST_URL,
            data: _this.projectCreate,
          })
          .then(({ data }) => {
            _this.projectCreated = true;

            this.$store.dispatch(CLEAR_CUSTOMER);

            _this.$router.push(
              _this.getDefaultRoute("project.detail", {
                params: { id: data.id },
              })
            );
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.formLoading = false;
          });
      } catch (error) {
        _this.logError(error);
      }
    },
    // getQuotation() {
    //   const _this = this;
    //   _this.$store
    //     .dispatch(GET, { url: "quotation/" + _this.quotation })
    //     .then(({ data }) => {
    //       _this.projectCreate.quotation = data.id;
    //       _this.projectCreate.name = data.job_title;
    //       _this.projectCreate.attention = data.attention;
    //       _this.projectCreate.reference = data.barcode;
    //     })
    //     .catch((error) => {
    //       _this.logError(error);
    //     });
    // },
  },
  beforeRouteLeave(to, from, next) {
    const _this = this;
    if (_this.projectCreated) {
      next();
    } else {
      _this.routePreventDialog = true;
      PreventDialogEventBus.$on("stay-on-page", function () {
        _this.routePreventDialog = false;
        next(false);
      });
      PreventDialogEventBus.$on("leave-page", function () {
        _this.routePreventDialog = false;
        next();
      });
    }
  },
  created() {
    const _this = this;
    _this.project = _this.lodash.toSafeInteger(_this.$route.params.id);
    _this.duplicate = _this.lodash.toSafeInteger(_this.$route.query.duplicate);
    _this.customer = _this.lodash.toSafeInteger(_this.$route.query.customer);
    // this.$store.commit(SET_CUSTOMER, _this.$route.query.customer);

    _this.projectProperty = _this.lodash.toSafeInteger(
      _this.$route.query.property
    );

    // _this.quotation = _this.lodash.toSafeInteger(_this.$route.query.quotation);
    // if (_this.quotation > 0) {
    //   _this.getQuotation();
    // }
    _this.projectCreate.customer = _this.vCustomerId;
  },
  mounted() {
    const _this = this;

    _this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Project", route: "project" },
      { title: "Create" },
    ]);

    this.$nextTick(() => {});
    this.getOptions();
  },
  destroyed() {
    this.$store.dispatch(RESET_STATE);
    this.$store.dispatch(RESET_CREATE_STATE);
    this.$store.dispatch(CLEAR_CUSTOMER);
  },
  beforeCreate() {
    this.$store.dispatch(CLEAR_CUSTOMER);
    this.$store.dispatch(RESET_CREATE_STATE);
  },
  computed: {
    ...mapGetters([
      "cc_persons",
      "reCustomerId",
      "vCustomerId",
      "reCustomerDialog",
      "vBillingContact",
      "vPropertyContact",
      "vProperty",
      "vBilling",
      "vPropertyId",
      "vBillingId",
      "vPropertyContactId",
      "vBillingContactId",
    ]),

    billingTypeLabel() {
      if (this.projectCreate.billing_type == 1) {
        return "Total Rate";
      } else if (this.projectCreate.billing_type == 2) {
        return "Rate Per Hour";
      }
      return null;
    },
    defaultDeadline() {
      let today = new Date();
      today.setDate(today.getDate() + 30);
      return today.toISOString().substr(0, 10);
    },
  },
};
</script>
